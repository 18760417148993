<template>
  <div id="app" class="d-flex" :class="[this.$route.meta.baseClass || '', layout]" :data-refera-version="appVersion"
    :data-build-version="buildVersion" :data-refera-environment="appEnvironment">
    <div class="initial-loader" v-if="isInitialLoadingRoute"></div>
    <component v-else :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
import { mapState } from 'vuex'
window.RevampConfig = Object.freeze({
  version: process.env.VUE_APP_VERSION,
  base_url: process.env.VUE_APP_API_BASE_URL,
  api: process.env.VUE_APP_API_BASE_URL + '/api',
})
const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const img = entry.target;
      img.src = img.dataset.src;
      img.classList.add('lazy-loaded')
      observer.unobserve(img);
    }
  });
}, {
  rootMargin: '0px',
  threshold: 0.1
});

export default {
  name: 'App',
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
      buildVersion: process.env.VUE_APP_BUILD_VERSION,
      appEnvironment: process.env.VUE_APP_ENV,
      mutationObserver: null
    }
  },
  created() {
    this.$store.dispatch('state/load')
  },
  mounted() {
    this.$nextTick(() => {
      document.querySelectorAll('[data-src]').forEach(el => observer.observe(el));
    });

    this.mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        mutation.addedNodes.forEach(node => {
          if (node.nodeType !== Node.ELEMENT_NODE) return
          if (node.dataset?.src) observer.observe(node);
          node.querySelectorAll('[data-src]').forEach(el => observer.observe(el));
        });
      })
    });

    this.mutationObserver.observe(this.$el, { childList: true, subtree: true });
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'base') + '-layout'
    },
    isInitialLoadingRoute() {
      const { name, path, matched } = this.$route
      return path === '/' && (!matched || !matched.length) && !name
    },
  },
  beforeDestroy() {
    this.mutationObserver?.disconnect();
    this.mutationObserver = null;
  }
}
</script>
<style lang="scss">
// @use './styles/custom-bootstrap.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './styles/global.scss';
</style>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app.base-layout .container-fluid {
  max-width: 1440px;
}
</style>
